.member-card-container {
  @apply md:ml-16 mt-11  gap-20  items-center  py-6 grid lg:grid-cols-4 md:grid-cols-3
 grid-cols-1;
}
.members-section {
  @apply font-medium leading-8 text-[28px] text-white capitalize md:mt-[126px] mt-[120px] md:ml-16 md:text-left text-center;
}
.nav-buttons {
  min-width: max-content;
  font-size: clamp(0.75rem, 0.644rem + 0.395vw, 1rem);
  /* font-size: clamp(1rem, 0.894rem + 0.395vw, 1.25rem); */
  /* max-width: fit-content; */
  border: 1px solid #ffc400;
  color:#ffc400;
  @apply flex items-center justify-center cursor-pointer gap-2 py-2 px-4 h-[39px] hover:text-[black] hover:bg-[#ffc400] bg-transparent rounded-[20px];
}

.container {
  padding-inline: clamp(20px, 5vw, 60px);
  max-width: 1440px;
  margin-inline: auto;
}

.team-page {
  overflow-x: hidden;
}

@media only screen and (min-width: 900px) {
  .team-page {
    padding-top: 80px;
  }
}

/* .header {
   height: fit-content;
   position: relative;
   background: white;
}

.header div:first-child {
   position: absolute;
   height: 50%;
   width: 100%;
   top: 50%;
   background-color: #080808;
}

.header .container img {
   position: relative;
   max-width: 100%;
   width: 100%;
   display: block;
   z-index: 0;
} */
.navsticky {
  top: 90px;
  position: sticky;
  position: -webkit-sticky;
  background-color: #00ad5f;
}
.meet-team {
  background-color: #080808;
  padding-block: 100px;
  position: relative;
}

.meet-team .blur-effect {
  position: absolute;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  left: 50%;
  bottom: -400px;
  transform: translateX(-50%);
  background: rgba(255, 196, 0, 0.15);
  filter: blur(200px);
  border: 1px solid red;
}

.our-team {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-inline: clamp(20px, 5vw, 60px);
  max-width: 1440px;
  margin-inline: auto;
  /* padding-bottom: 60px; */
  color: white;
}

.green-blur-effect {
  position: absolute;
  width: 694px;
  height: 694px;
  top: 220px;
  right: 0px;
  border-radius: 50%;
  background: #00ad5f;
  opacity: 0.4;
  filter: blur(250px);
  z-index: 3;
}

.our-team div:first-child {
  width: 45%;
}

.our-team div:last-child {
  width: 55%;
}

.our-team h3 {
  font-weight: 400;
  font-size: clamp(12px, 2.5vw, 22px);
  line-height: 25px;
  position: relative;
  margin-bottom: 20px;
}

.our-team h3::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  left: 140px;
  height: 1px;
  width: 200px;
}

.our-team h2 {
  font-weight: 700;
  font-size: clamp(28px, 5vw, 60px);
  line-height: 120%;
  color: #00ad5f;
}
.ourteamtext {
  font-weight: 700;
  font-size: clamp(1.75rem, 3.1621vw, 3.75rem);
  line-height: 120%;
  color: #f8ff29;
}

.our-team p {
  max-width: 51ch;
  font-weight: 400;
  font-size: clamp(16px, 2.1vw, 20px);
  line-height: clamp(25px, 3vw, 32px);
  margin-left: auto;
  color: #f8ff29;
}

.large-text {
  font-weight: 500;
  font-size: clamp(80px, 16.5vw, 240px);
  /* font-size: 200px; */
  text-transform: capitalize;
  color: #00ad5f;
  mix-blend-mode: color-dodge;
  opacity: 0.3;
  overflow-x: hidden;
  text-overflow: clip;
  white-space: nowrap;
  width: fit-content;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 28px;
  margin-bottom: 15px;
  z-index: 10;
}

.team-members {
  display: grid;
  gap: 18px;
  padding-inline: clamp(20px, 5vw, 60px);
  max-width: 1440px;
  margin-inline: auto;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 185px);
}

.team-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  aspect-ratio: 0.9;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.71) 100%);
  backdrop-filter: blur(13px);
  border-radius: 16px;
  z-index: 3;
  text-align: center;
}

.team-card .avatar img {
  width: 100px;
  border-radius: 50%;
}

.team-card p {
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
}

.member-details {
  display: none;
  color: #272927;
}

.member-details h3 {
  font-weight: 700;
  font-size: 20px;
}

.member-details p {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.member-details .country {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
}

.member-details .country img {
  width: 21px;
  height: 16px;
}

.team-card:hover .member-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #BB6BD9 -8.62%, rgba(255, 196, 0, 0.29) 100%);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  align-items: center;
  justify-content: center;
}

.meet-team-nav {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin-inline: auto;
  margin-top: 70px;
  position: relative;
  z-index: 3;
}

.meet-team-nav > div {
  background: #f2f2f2;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.meet-team-nav > div:hover {
  background: #BB6BD9;
}

.culture {
  background: #fafafa;
  padding-block: 140px;
  position: relative;
  z-index: 4;
}

.culture h1 {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(28px, 5vw, 40px);
  line-height: 120%;
  text-align: center;
  text-transform: capitalize;
  max-width: 30ch;
  margin-inline: auto;
  margin-bottom: 126px;
}

.culture > div > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

.culture-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.culture-details h2 {
  font-weight: 500;
  font-size: clamp(24px, 6vw, 32px);
  line-height: 140%;
  text-transform: capitalize;
  color: #272927;
}

.culture-details p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272927;
  max-width: 35ch;
}

.lottie {
  width: 110px;
  height: 110px;
}

@media (max-width: 900px) {
  .green-blur-effect {
    position: absolute;
    width: 280px;
    height: 400px;
    right: -2px;
    filter: blur(250px);
    opacity: 0.8;
  }

  .meet-team {
    padding-block: 50px;
  }

  .our-team {
    flex-direction: column;
    gap: 20px;
    margin-top: 100px;
  }

  .our-team div:first-child,
  .our-team div:last-child {
    width: 100%;
  }

  .our-team h3::after {
    width: 55%;
    left: 120px;
  }

  .our-team p {
    margin-left: 0;
    max-width: 100%;
  }

  .large-text {
    margin-block: 5px;
  }

  .team-members {
    grid-template-columns: repeat(auto-fit, 150px);
  }

  .team-card .avatar img {
    width: 60px;
  }

  .team-card p {
    font-size: 16px;
  }

  .member-details h3 {
    font-size: 14px;
  }

  .member-details p {
    font-size: 10px;
    line-height: 24px;
  }
}

@media (max-width: 820px) {
  .meet-team .blur-effect {
    display: none;
  }

  .culture {
    padding-block: 60px;
  }

  .culture > div > div {
    justify-content: center;
  }

  .culture-details {
    text-align: center;
    align-items: center;
  }

  .culture h1 {
    margin-bottom: 60px;
  }
}
