

@keyframes fadeOut {
    80% {  opacity: 1; }  
100% {  opacity: 0;}
  }

  #fade_div{
    animation: fadeOut 5s infinite alternate;
    
    }