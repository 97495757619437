

@media screen and (min-width: 900px) {
    #card-container:hover #hoverlabel{
        display:flex;    
       
    }
  }

  #card-container{
    position: relative;
  }

  #hoverlabel{
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 0%;
    right: auto;
    bottom: auto;
    
    justify-items: center;
    width: 240px;
    height: 240px;
    padding: 32px;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, 0px);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;

    border-radius: 56% 44% 39% 61% / 49% 41% 59% 51%;
    transition: border-radius 1s ease-out;
    animation: animation-1 4s ease-in-out infinite both alternate;

  }

  @keyframes animation-1 {
    0% {border-radius: 56% 44% 39% 61% / 49% 41% 59% 51%;}
    100% {border-radius: 71% 29% 68% 32% / 47% 45% 55% 53%;}
  }
  
  #card-container:hover #hoverlabel{
    opacity: 0.9;
    
    justify-content: center;
    
    transition: all .4s ease-in-out;
  }