body {
  background: #fafafa;
}
.splide.is-focus-in .splide__arrow:focus {
  outline: none !important;
  outline-offset: 3px;
}
.hero-image {
  position: relative;
}

.hero-image .incubation-hero-overlay {
  position: absolute;
  top: 0;
  background: linear-gradient(
    90deg,
    #000000 29.83%,
    rgba(2, 2, 2, 0.04) 96.25%
  );
  color: #f1f1f1;
  height: 100%;
  color: white;
}

.hero-image .incubation-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  text-transform: capitalize;

  color: #ffffff;
}

.incubation-hero-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.005em;

  color: #ffffff;
}
/* top: 0px; */

.overlay {
  background: linear-gradient(
    90deg,
    #000000 29.83%,
    rgba(2, 2, 2, 0.04) 96.25%
  );
  height: 519px;
}
.profile .icon img {
  width: 18px;
  height: 20px;
}

.inBtn {
  border: 1px solid black;
  cursor: pointer;
}
.inBtn:hover {
  border: 2px solid black;
}
.incubation-section .incubation-country {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;

  /* BG color */

  color: #272927;
}
.incubation-section-carouselImg {
}
.incubation-section-carouselImg img {
  /* width: 100%; */
  /* height: 292px; */
  /* height: auto; */
}

.profile.icon img {
  width: 19px;
  height: 20px;
}

.incubation-centers-section {
  margin-bottom: 120px;
}
.incubation-centers-section .splide__arrow--next:active {
  background: #272927;
  color: white;
  border: 1px solid black;
}
.incubation-centers-section .splide__arrow--prev:active {
  background: #272927;
  color: white;
  border: 1px solid black;
}
.spanLeft {
  transform: scale(-1, 1);
}
.incubation-centers-section .splide__arrows .splide__arrow--next,
.splide__arrow--prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid;
  outline: none;
}
.incubation-centers-section .splide__arrows {
  position: absolute;
  bottom: -6%;
  left: 50%;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .hero-image .incubation-hero-overlay {
    width: 100%;
    height: 624px;
  }
  .hero-image img {
    height: 624px;
  }
  .hero-image .incubation-heading {
    font-weight: 700;
    font-size: 56px;
    line-height: 120%;
    width: 60%;
    padding-left: 40px;
  }
  .incubation-hero-text {
    width: 624px;
    padding-left: 40px;
  }
  .overlay {
    height: 624px;
  }

  .incubation-centers-section .splide__arrows {
    position: absolute;
    bottom: 0px;
    left: auto;
    right: 10%;
  }
  .incubation-centers-section .splide__arrows .splide__arrow--next,
  .splide__arrow--prev {
    width: 80px;
    height: 80px;
    background: #f7f7f7;
    border-radius: 50%;
    border: none;
    opacity: 1;

    outline: none;
  }
  .incubation-centers-section .splide__arrow--next:hover {
    background: #fff;
    border: 0.5px solid #272927;
    opacity: 1;
  }
  .incubation-centers-section .splide__arrow--prev:hover {
    background: #fff;
    border: 0.5px solid #272927;
    opacity: 1;
  }
  .incubation-centers-section .splide__arrows .splide__arrow--prev {
    /* transf
    orm: scale(-1, 1); */
  }
  .incubation-contact {
    justify-content: left;
  }
  .incubation-section-carouselImg img {
  }
}
