.navigateLinks {
  /* font-size: clamp(0.75rem, 0.644rem + 0.395vw, 1rem); */
  font-size: clamp(0.75rem, 0.624rem + 0.469vw, 1rem);
  @apply py-1 text-sm capitalize text-center items-center flex justify-center border-solid border-x-0 border-t-0 border-b-2 border-transparent md:hover:border-[#ffc400];
}

.navigateLinks.active {
  @apply border-[#ffc400];
}
iframe { display: none; }
select.goog-te-combo {
  padding: 8px 14px;
  border-radius: 10px;
  border: 2px solid #bbb;
}