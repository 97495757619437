.bgIncubation {
  padding: 16px;
  background: url("../../../assets//Images/Rectangle468.webp");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
}
.incubation .intro {
  color: #fbfffb;
  display: flex;
  gap: 1rem;
  align-items: center;
  /* BG color */
  margin-bottom: 1.25rem;
}

.incubation .intro hr {
  flex: 1 1 auto;
  max-width: 6rem;
  border: 1px solid #ffffff;
}

@media screen and (min-width: 768px) {
  .incubation .intro hr {
    max-width: 12rem;
  }
}

.incubation .contents .heading h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;

  text-transform: capitalize;

  color: #fbfffb;
}
.rotateImage {
  position: absolute;
  top: -240px;
  display: none;
}
.rotateImage img {
  width: 357px;
}

.incubation .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */

  text-transform: capitalize;

  /* BG color */

  margin-bottom: 40px;
}
.incubation .text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  width: 396px;
}

.incubation .more {
  background: #ffffff;
}
.incubation .more .title {
  display: flex;
  padding: 16px;
  gap: 6px;
  align-items: center;
}
.more-wrap {
  margin-bottom: 40px;
  border-radius: 8px;
}

.more .wrap {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 6px;
}

.incubation .button {
  display: flex;
}

.incubation .button p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  color: #ffffff;
}
.button button {
  cursor: pointer;
  background: none;
  height: 60px;
  width: 216px;
  border: 1px solid #fbfbfb;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
