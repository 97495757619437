
  /* CSS for modal appearance */
  @keyframes elasticZoomIn {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* CSS for modal disappearance */
  @keyframes elasticZoomOut {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .modal-content {
    border-radius: 9px;
    animation-duration: 0.5s;
    animation-timing-function:cubic-bezier(0.32, 0.31, 0.48, 0.82);
    animation-name: elasticZoomIn;
  }
  /*# sourcemappingurl=app.css.map */
  /* Ripple effect */
  .ripple {
    background-position: center;
    transition: all 0.7s;  
  
  }
  .ripple:hover {
    background: #f5be0a radial-gradient(circle, transparent 1%, #f5be0a 1%) center/15000%;
  }
  .ripple:active {
    background-color: #fac823 !important;
    transform: scale(0.87);
    background-size: 100%;
    transition: background 0s;
  }
  
  .ploader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    transform: rotate(45deg);
  }
  .ploader::before {
    content: '';  
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: -24px;
    animation: animloader 4s ease infinite;
  }
  .ploader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: #fac82388;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    animation: animloader2 2s ease infinite;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }
    12% {
      box-shadow: 0 24px #fac823, 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }
    25% {
      box-shadow: 0 24px white, 24px 24px white, 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }
    37% {
      box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px white;
    }
    62% {
      box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px white, 24px 48px white, 0px 48px white;
    }
    75% {
      box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px white, 0px 48px white;
    }
    87% {
      box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px white;
    }
    100% {
      box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }
  }
  
  @keyframes animloader2 {
    0% {
      transform: translate(0, 0) rotateX(0) rotateY(0);
    }
    25% {
      transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }
    50% {
      transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }
    75% {
      transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }
    100% {
      transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
  }
      