.balance-text{
    color: blueviolet;
}

.flower-container {
    position: relative;
    width: 50%;    

  }
  
/* Style individual flower images */
.flower {
    position: absolute;    
    top: 0;
    left: 0;
    border-radius: 50px;    
    /* Apply a 45% overlap */
    width: 30px;
    height: 30px;
    border: 1px solid rgb(255, 170, 11);
  }
  
  /* Adjust z-index for proper layering if needed */
  .flower:nth-child(1) {
    z-index: 2;        
  }
  
  .flower:nth-child(2) {
    z-index: 3;    
    transform: translateX(60%);
  }
  
  .flower:nth-child(3) {
    z-index: 4;    
    transform: translateX(120%);
  }
  .flower:nth-child(4) {
    z-index: 5;    
    transform: translateX(180%);
  }

  .flower:nth-child(5) {
    z-index: 5;    
    transform: translateX(240%);
  }
  