@media only screen and (min-width: 768px) {
  .section1 {
    padding: 40px;
  }
  .banner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 60px;
  }
  .bannerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 532px;
    padding: 0px;
    gap: 20px;
  }
  .bigText {
    font-weight: 600;
    font-size: 56px;
    line-height: 1.2;
  }
  .smallText {
    height: 91px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */

    /* BG color */
  }
  .bannerBtn1,
  .bannerBtn2 {
    cursor: pointer;
    min-width: 220px;
    height: 80px;
    border-radius: 64px;
    font-size: 18px;
  }
  .bigImg img {
    width: 100%;
  }
  .trIcon img {
    width: 78px;
    height: 78px;
    position: absolute;
    top: -15px;
    left: -10px;
  }
  .polygonIcon img {
    width: 40px;
    height: 40px;
  }
  .polygonIcon {
    position: absolute;
    bottom: 60px;
    z-index: 1;
    left: -20px;
  }
  .starIcon img {
    width: 100px;
    height: 100px;
  }
  .starIcon {
    position: absolute;
    right: 42%;
    top: 20px;
    z-index: -1;
  }

  .circleIcon img {
    width: 54px;
    height: 54px;
  }
  .circleIcon {
    position: absolute;
    bottom: -20px;
    left: 27%;
  }
  .figures .content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .images .mobileB {
    display: none;
  }
  .images .desktop {
    display: none;
  }

  .images .desktop .one {
    position: absolute;
    left: 0;
    top: 0;
  }
  .images .desktop .two {
    /* margin-left: -120px; */
    position: absolute;
    left: 18%;
    top: 0;
  }

  .images .desktop .three {
    left: 40%;
    top: 0%;
    position: absolute;
  }
  .images .desktop .four {
    /* margin-left: -80px; */
    position: absolute;
    right: 0px;
    top: 0;
  }
  .images .desktop .five {
    position: absolute;
    right: 0;
    top: 0;
    /* right: 14px; */
  }
  /* about us */

  .about-us .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hr-sect {
    gap: 20px;
    flex-basis: 50%;
  }
  .gallery-btns {
    display: none;
  }

  /* carousel */

  @media only screen and (min-width: 900px) {
    .about-us .splide__arrow--prev {
      left: 2em;
      width: 70px;
      position: absolute;
      height: 70px;
      top: 44%;
      background-color: black;
      opacity: 1;
      border: none;
      color: #fff;
    }
    .about-us .splide__arrow--next {
      right: 1.7em;
      width: 70px;
      height: 70px;

      border: none;
      position: absolute;

      top: 42.5%;
      opacity: 1;
    }
    .splide__arrow svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .about-us .splide__arrow--prev:hover {
    background-color: white;
    border: 1px solid black;
  }
  .about-us .splide__arrow--prev:hover svg {
    fill: black !important;
  }

  .about-us .splide__arrow--next:hover {
    background-color: black;
  }
  .about-us .splide__arrow--next:hover svg {
    fill: white !important;
  }

  /* end */
  .content-gallery .gallery-btn1 {
    position: absolute;
    top: 60%;
    left: 0px;
  }
  .about-us .content-wrap {
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .about-us .content {
    text-align: start;
  }
  .about-us .content-title {
    margin-bottom: 20px;
    font-size: 36px;
  }
  .about-us .content a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  .about-us hr {
    border: 1px solid #bdbdbd;
  }
  .d-gallery-btns {
    display: none;
    width: 80px;
    height: 80px;
  }
  .d-gallery-btn1,
  .d-gallery-btn2 {
    position: absolute;

    top: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    border: none;
    cursor: pointer;
  }
  .content-gallery {
    background: none;
  }
  .about-us .gallery-btns {
    display: none;
  }
  .gallery-btn1 {
    display: none;
  }
  .d-gallery-btn1 {
    /* background: #fff; */
    color: black;
    left: 1em;
    z-index: 1;
  }
  .d-gallery-btn1:hover {
    background: #fff;
  }
  .d-gallery-btn2 {
    right: 25px;
  }
  .d-gallery-btn2:hover {
    background-color: black;
  }
  /* end of about us */

  /* services */
  .services {
    padding: 68px;
  }
  .services .intro {
    font-size: 22px;
    justify-content: flex-start;
    gap: 40px;
    line-height: 25px;
  }
  .services .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .service .intro hr {
    width: 245px;
  }
  .services .heading h4 {
    margin-bottom: 60px;
    font-size: 2rem;
  }

  .service-card .icon img {
    width: 60px;
    height: 60px;
  }

  .service-card {
    height: 332px;
    gap: 16px;
  }

  .service-card .title {
    font-size: 28px;
    line-height: 36px;
  }
  .service-card .text {
    font-size: 16px;
    line-height: 24px;
  }

  /* product */

  .products {
    margin-bottom: 120px;
  }
  .product .list .list-monile {
    display: none;
  }
  .products .intro hr {
    width: 368px;
  }
  .product button {
    height: 80px;
  }

  .wrapper {
    gap: 40px;
  }
  .products .intro {
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 1.5rem;
  }
  .products .heading {
    padding-top: 0px;
  }

  .laptop {
    width: 100%;
  }
  .products .text {
    font-size: 16px;
    line-height: 28px;
    width: 549px;
  }
  .products .heading {
    font-size: 36px;
    line-height: 140%;
    width: 550px;
  }
  .products .list {
    margin-bottom: 40px;
  }
  .products .list span p {
    font-size: 18px;
  }

  /* incubations
  centers   */

  .bgIncubation {
    border-bottom-left-radius: 40px;
  }
  .incubation {
    margin-top: 80px;
  }
  .incubation .intro {
    justify-content: flex-start;
    gap: 40px;
  }

  .incubation .contents .heading h4 {
    width: 682px;
    font-weight: 700;
    font-size: 44px;
    margin-top: 40px;
    line-height: 120%;
  }

  .bgIncubation {
    position: relative;
    background: url("../../assets/Images/Rectangle\ 468\ \(1\).png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .incubation {
    padding: 20px;
  }
  .incubation.wrap .contents .heading {
    padding-left: 0px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .incubation.wrap .button button {
    height: 80px;
  }
  .rotateImage img {
    width: 100%;
    margin-top: 0px;
  }
  .rotateImage {
    position: absolute;
    right: 0;
    top: 0;
  }
  .lightBlue {
    width: 279px;
    height: 491px;
  }
  .incubation .contents {
    position: relative;
  }
  .down {
    background-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15px;
    left: 50px;
  }
  /* our community */

  .community {
    position: relative;
    display: none;
  }

  /* .community .card {
      position: absolute;
      height: 300px;
      width: 600px;
      top: 465px;
      left: calc(50% - 300px);
      display: flex;
    }
    .community .card1,
    .card2,
    .card3,
    .card4 {
      display: flex;
      flex-direction: column;
      height: 200px;
      width: 400px;
      border-radius: 10px;
      transition: 0.4s ease-out;
      position: relative;
      left: 0px;
    } */
  /* .community .card1 :not(:first-child),
    .card2:not(:first-child),
    .card3 :not(:first-child),
    .card4 :not(:first-child) {
      margin-left: -150px;
    } */
  /* .community .card1 {
    }
    .community .card3:hover {
      transform: translateX(60px);
      z-index: 2;
      transition: 0.4s ease-out;
    }
    .community .card4:hover {
      transform: translateX(20px);
      transition: 0.4s ease-out;
    }
    .community .card3:hover ~ .community .card4 {
      position: relative;
      left: 50px;
      transition: 0.4s ease-out;
    } */

  .uni {
    background: #fafafa;
    margin: auto;
  }
  .uni .title {
    margin: auto;
    width: 60%;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .uni .title .hr-sect {
    font-size: 22px;
  }
  .uni .heading {
    margin: auto;
    margin-bottom: 20px;
    font-size: 40px;
  }

  .uni-carousel {
    background-repeat: no-repeat;

    background-size: cover;
  }
  .uni-carousel hr {
    width: 245px;
    border: 1px solid #ffffff;
  }
  .uni-carousel .splide__arrow--prev {
    background-color: #b3fc47 !important;
  }
  .uni-carousel .splide__arrow--next {
    display: none;
    background-color: #b3fc47 !important;
  }
  .uni-carousel .splide__arrow--prev img {
    transform: scale(-1, 1);
  }

  .uni-carousel .title {
    padding-left: 40px;
    gap: 40px;
  }
  .uni-carousel .heading {
    margin: 0px;
    padding-left: 40px;
    width: 600px;
  }
  .universities .img-wrap img {
    width: 5rem;
  }
  .tourImage img {
    width: 100%;
    border-radius: 24px;
  }

  .carouselImg .overText {
    top: 67%;
  }

  .overText .topText {
    position: absolute;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    left: 20px;
    top: 0px;
    line-height: 120%;
    /* identical to box height, or 29px */

    text-align: center;
    text-transform: capitalize;

    color: #ffffff;
    height: 54px;
    border-radius: 0px 24px 0px 0px;
  }

  .carouselImg {
    position: relative;
    display: flex;
  }
  .overText .bottom .overBtn {
    width: 190px;
    height: 50px;
    background: #b3fc47;
    box-shadow: 0px 4px 24px rgba(179, 252, 71, 0.2);
    border-radius: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
  .overText .bottom {
    font-size: 14px;
    display: flex;
    gap: 120px;
    padding-left: 20px;
    padding-right: 10px;
    align-items: baseline;
    padding-top: 8%;
  }
  .overText .bottom p {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    /* identical to box height, or 48px */

    text-align: center;
    text-transform: capitalize;

    color: #ffffff;
  }

  .overBtn {
    margin-top: 20px;
  }
  .overText .overBtn img {
    width: 27px;
    height: 27px;
  }

  /* team */

  .team .button button {
    margin-bottom: 120px;
  }
  .team .heading h3 {
    margin: auto;
    width: 682px;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 55px;
  }
  .team .title {
    margin: auto;
    width: 50%;
  }
  .team .title .hr-sect {
    gap: 20px;
    font-size: 22px;
    gap: 20px;
  }
  .team {
    background: linear-gradient(to right, #fff3ca, #f0faf5, #c1ebd7);
  }

  .team.cards div {
    text-align: center;
  }
  .team .heading {
    margin-bottom: 0px;
  }
}
