.uni {
  padding: 16px;
}
.uni .heading {
  margin-bottom: 20px;
  text-align: center;
}

.universities img {
  width: 70%;
}
.universities {
  margin-top: 40px;
}
.uni-carousel {
  background: url("../../../assets/Images/tourBackground.png");
  color: #fff;
  background-size: contain;
  background-repeat: repeat;
  padding: 20px 16px 20px 16px;
}
.universities .img-wrap {
  flex-basis: 20%;
}
.uni-carousel .splide__arrow--prev {
  background-color: #b3fc47 !important;
}
.uni-carousel .splide__arrow--next {
  background-color: #b3fc47 !important;
}
.uni-carousel .title {
  font-style: normal;
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 20px;
}
.uni-carousel hr {
  width: 35%;
  border: 1px solid #ffffff;
}
.uni-carousel .heading {
  color: #fff;
}
.carouselImg {
  display: flex;
  position: relative;
}
.overText {
  display: flex;
  flex-direction: column;
}
.overText .bottom {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  width: 100%;
}
.overText .bottom .overBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  font-weight: 400;
  width: 150px;
  height: 36px;
  padding: 16px;
  background: #b3fc47;
  box-shadow: 0px 4px 24px rgba(179, 252, 71, 0.2);
  border-radius: 40px;
  cursor: pointer;
}
.overText .bottom .overBtn p {
  font-size: 10px;
  line-height: 18px;
}
@media (max-width: 500px) {
  .overText .bottom {
    padding-top: 2%;
    gap: 60px;
    margin-top: 15px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 12px;
    gap: 20px;
  }
  .overText .bottom p {
    font-size: 20px;
  }
  .overText .topText p {
    font-size: 12px;
    padding-left: 14px;
  }
}
@media (max-width: 378px) {
  .overText .bottom {
    padding-top: 0px;
    gap: 60px;
    margin-top: 13px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .overText .bottom .overBtn {
    width: 131px;
    padding: 0px;
    padding-left: 4px;
    padding-right: 4px;
    justify-content: space-evenly;
    font-size: 10px;
  }
  .overText .bottom p {
    font-size: 16px;
  }
}
.carouselImg .overText {
  display: flex;
  position: absolute;
  top: 66%;
}
.tourImage img {
  width: 100%;
  border-radius: 20px;
}
.overText {
  display: none;
}
.marquee-container {
  overflow: hidden;
}

.marquee-content {
  display: flex;
  animation: marquee 20s linear infinite;
  
}

@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

/* Each image container */
.marquee-content > div {
  margin-right: 24px; /* Adjust the spacing between images */
}