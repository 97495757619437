.bdx p p{
    line-height: 2 !important;
}
.ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px !important;
}

.bdx p ul li p {
    /* line-height: 0.5 !important; */
}
.bdx p ul li {
  line-height: 2em;
  margin-left: 59px;
}
.bdx p p, .bdx p ul li {
   text-align: justify;
   /* font-size: 1.5em; */
}
/*

.bdx p span span strong img {
    width: 80%;
}

.bdx p h4 span {
    font-size: 24px !important;
} */