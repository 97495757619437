@keyframes fadeIn {
    from { opacity: 0; transform: translateX(-400px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeIn2 {
    from { opacity: 0; transform: translateX(400px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  .waitlistAnim {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
  }

  
  .waitlistAnim2 {
    opacity: 0;
    animation: fadeIn2 1s ease-out forwards;
  }
  
  .animation-delay-1 { animation-delay: 0.2s; }
  .animation-delay-2 { animation-delay: 0.4s; }
  .animation-delay-3 { animation-delay: 0.6s; }
  .animation-delay-4 { animation-delay: 0.8s; }
  .animation-delay-5 { animation-delay: 1s; }
  .animation-delay-6 { animation-delay: 1.2s; }
  