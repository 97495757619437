.services {
  background: radial-gradient(#ffc4004d, #ffffff);
}

.services .intro {
  text-transform: uppercase;
  color: #272927;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.services .intro hr {
  flex: 1 1 auto;
  max-width: 12rem;
  color: rgba(79, 79, 79, 0.5);
}

.services .heading h4 {
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 40px;
}

.services .buttons .bannerBtn2 {
  border: 1px solid black;
}

.service {
  padding: 10px;
}

.service-card {
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  mix-blend-mode: normal;
}
@media (max-width: 500px) {
  .service {
    justify-content: center;
  }
}

.service-card .icon img {
  width: 35px;
  height: 32px;
}

.services .buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
}
