.team {
  /* background: radial-gradient(#fff3ca 40%, #c1ebd7, #f0faf5); */
  /*   */
}
/* .team .intro {
  padding: 16px;
} */
.team .heading {
  padding: 16px;
  text-align: center;
}
.team .title {
  padding: 16px;
  padding-top: 140px;
}
.team .cards {
  background: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.team .cards .card,
.dcard {
  width: 188px;
  height: 199px;
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cards .card,
.dcard p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-transform: capitalize;
}
.team .button {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team .button button {
  height: 80px;
  border: 1px solid #272927;
  background: #fff;
  border-radius: 64px;
  width: 216px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.team .button button:hover {
  border: 2px solid !important;
  opacity: 0.9;
}

.team.cards p {
  font-weight: 500;
}
.team.cards div {
  width: 170px;
  text-align: center;
}

@media (max-width: 390px) {
  .team.cards div {
    width: 150px;
    text-align: center;
  }
}
.team.cards div div div {
  display: flex;
  align-items: center;
  justify-content: center;
}
