
.hotspotPoint{
    background-color: #0001;
  
  
  }
  .hotspotPoint:hover ~ .hidden-x {
    display: block !important;
  }
  .hidden-x {
    display:none;
    background: white;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    z-index: 2000;  
    transition: all 0.3s;
    transform: scale(1.2);
  }
  .hotspotlist{
    padding: 10px 38px 10px 9px;
    white-space: nowrap;
  }
  .hotsportBtn{
    position: relative;
    font-size: 14px;
      font-weight: 700;
      background: #00000091 !important;
      color: white;
      padding: 10px;
  }
  .hotsportBtn:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      top: 0;
      filter: blur(36px);
      z-index: -1;
  }
  .hotspotlist:focus{
    background: rgb(0 0 0 / 70%) !important;    
    border-radius: 10px;
  }
  .vSlide-drawer-rotate{
    transform: scaleX(-1);
  }
  
  .hotspotlist:active{
    transform: scale(0.9);
  }
  .vSlide{
    position: absolute;
    left: -5px;
    bottom: 15%;
    transition: all .5s;
    width: 104%; 
  }
  .vSlideI{ 
    position: absolute;
    left: 8px;
    bottom: 3%;     
  }
  .vSlideClosed{
    left: -90%;
  }
  .vSlide-content{
    position: absolute;  
    padding: 10px;
    bottom: 150px;
   
  }
  
  .vSlide-drawer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    border-radius: 8px;
    border: 2px solid #eee;
  }
  .ActiveScene2{
    border: 1.5px solid #FFC400;    
  }

  .ActiveScene{     
    position: relative;
  }
  .ActiveScene:after {
    content: '';
    width: 100%;
    height: 2.4px;
    background: #FFC400;
    position: absolute;
    left: 0;
    bottom: 3px; 
  }

  .loader123 {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
  }
  .loader123::after,
  .loader123::before {
    content: '';  
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #FFF;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .loader123::after {
    animation-delay: 1s;
  }
  
  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .fTitle{
    font-weight: 600;
    font-size: 24px;
    text-shadow: 1px 2px #666;
  }    
  .x-left {
    left: 0;
  }
  
  .x-right {
    right: 0;
  }
  
  .x-center {
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Vertical Positions */
  .y-top {
    top: 0;
  }
  
  .y-bottom {
    bottom: 0;
  }
  
  .y-center {
    top: 50%;
    transform: translateY(-50%);
  }
  