.community {
  padding: 16px;
  margin-bottom: 20px;
}
.community .contents {
  margin-bottom: 40px;
}
.community .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */

  text-transform: capitalize;

  /* BG color */
  margin-bottom: 40px;
  text-align: center;
}

.community .card1,
.card2,
.card3,
.card4 {
  background: #131313;
  color: #fff;
  padding: 20px;
}
.community .card1 {
  border-left: 4px solid #ff5619;
}
.community .card2 {
  border-left: 4px solid #ffc400;
}
.community .card3 {
  border-left: 4px solid #5151fd;
}
.community .card4 {
  border-left: 4px solid #00ad5f;
}
.community .card1,
.card2,
.card3,
.card4,
.main-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}
.community .card1,
.card2,
.card3,
.card4 .subtext p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
}
.card .subtext span {
  display: flex;
  gap: 12px;
}
.card .subtext span img {
  width: 28px;
  height: 24px;
}
