.custom-dropdown-language{
    /* Add your styles here */
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

/* Customize the options in the dropdown */
.custom-dropdown-language .custom-options{
    /* Add your styles here */
    color: #333;
    font-size: 14px;
}

#goog-gt-tt{
    display: none !important;
}
