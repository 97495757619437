.products-hero-image {
  position: relative;
}
.products-hero-text {
  display: flex;
  flex-direction: column;
  gap: 360px;
}
.products-hero-text h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  text-align: center;

  background: linear-gradient(90.83deg, #ffffff 8.84%, #fdd85c 105.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.products-page .products-section {
  position: sticky;
}

.products-page .buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  position: unset;
}

.products-hero-text .hero-mini-text p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  text-align: center;
}
.products-page .buttons .bannerBtn2 {
  border: 0.5px solid #ffffff;
  color: #fff;
  padding: 16px;
}

@media (min-width: 768px) {
  .products-hero-text {
    width: 50%;
    left: 10%;
    gap: 0px;
  }
  .products-hero-text h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 79px;
    text-transform: capitalize;
    text-align: start;
    background: linear-gradient(270.19deg, #f7c510 7.65%, #ffffff 103.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .products-page .buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    position: unset;
  }
  .products-hero-text .buttons .bannerBtn1,
  .bannerBtn2 {
    width: 216px;
  }
  /*  */

  .products-hero-text .hero-mini-text p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    padding: 0px;
    text-align: start;
  }
}
