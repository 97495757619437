@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter Regular"), local("Inter-Regular"),
    url("./Inter-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local("Inter Medium"), local("Inter-Medium"),
    url("./Inter-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local("Inter Semibold"), local("Inter-Semibold"),
    url("./Inter-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter Bold"), local("Inter-Bold"),
    url("./Inter-Bold.ttf") format("truetype");
  font-display: swap;
}

/* Recoleta Font */
@font-face {
  font-family: "Recoleta";
  src: url("./Recoleta-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta";
  src: url("./Recoleta-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Graphik font */
@font-face {
  font-family: "Graphik";
  src: url("./Graphik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("./Graphik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("./Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
