/* Define the grid container */
.grid-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
}

/* Define the grid items */
.grid-item {
  flex: 1;
  min-width: 100px; /* Adjust as needed */
  margin: 10px; /* Add spacing between items */
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

/* Apply a media query for screens less than 300px wide */
@media (max-width: 300px) {
  /* Change the flex direction to stack items vertically */
  .grid-container {
    flex-direction: column;
  }
}

/* Add more styling as needed */

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
}

.blog-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-gap: 10px;
}

.blog-grid-item {
  height: auto;
  grid-row: 1;
  grid-row-end: span 1;
  position: relative;
}
.blog-grid-item-content {
  position: absolute;
  bottom: 10px;
  z-index: 3;
  color: white;
  font-weight: 200;
  padding: 5px 10px;
}
.blog-card::after {
  content: "";
  display: block;
  width: 100%;
  background: rgb(4, 4, 4);
  background: linear-gradient(360deg, rgba(4, 4, 4, 0.8) 11%, rgba(0, 0, 0, 0.5) 64%, rgba(255, 255, 255, 0) 100%);
  height: 40%;
  position: absolute;
  z-index: 0;
  bottom: 5px;
  border-radius: 0px 0px 15px 15px;
}

.blog-grid-item-img {
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 5px;
}

.blog-grid-item-1 {
  grid-row-end: span 1;
}

.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: rgba(79, 79, 79, 0.5);
  height: 1px;
  margin: 0 0.5rem;
  max-width: 12rem;
}

.about-us .heading h1 {
  text-align: center;
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 4.5rem;
}

.about-us .content-title {
  font-size: 1.5rem;
  line-height: 1.4;
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 1.25rem;
  font-weight: 500;
}

.about-us .content-title h4 {
  font-weight: 500;
}
.custom-text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}