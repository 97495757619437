.virtual-tour-section {
  background: url("../../../assets/Images/tourBackground.webp") no-repeat;
  background-position: center;
  background-size: cover;
}

.swiper-slide {
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .swiper-slide {
    width: 60%;
  }
}

.blog-card::after {
  content: "";
  display: block;
  width: 100%;
  background: rgb(4, 4, 4);
  background: linear-gradient(360deg, rgba(4, 4, 4, 0.8) 11%, rgba(0, 0, 0, 0.5) 64%, rgba(255, 255, 255, 0) 100%);
  height: 40%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  border-radius: 0px 0px 15px 15px;
}

.blog-card2::after {
  content: "";
  display: block;
  width: 100%;
  background: rgb(4, 4, 4);
  background: linear-gradient(360deg, rgba(4, 4, 4, 0.8) 11%, rgba(0, 0, 0, 0.5) 64%, rgba(255, 255, 255, 0) 100%);
  height: 40%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  border-radius: 0px 0px 15px 15px;
}
.vtour-sect {
  font-weight: 400;
  font-size: clamp(1rem, 1.5637rem + -0.6263vw, 1.375rem);
  line-height: 25px;
  position: relative;
  margin-bottom: 20px;
}

.vtour-sect::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  /* right: 90px; */
  height: 1px;
  /* width: 200px; */
}
.ant-form-item-required {
  color: aliceblue;
}

.universal-tour .splide__arrow--prev svg {
  transform: rotate(180deg) !important;
}

.universal-tour .splide__arrow--next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid;
  outline: none;
}

@media screen and (min-width: 768px) {
  .universal-tour .splide__arrow--next {
    width: 80px;
    height: 80px;
    background: black;
    border: none;
    opacity: 1;
    outline: none;
  }

  .universal-tour .splide__arrow--next svg {
    fill: white;
  }
}
.nextBtn{
  position: relative;
  cursor: pointer;
  user-select: none;
}
.nextBtn span{
  transition: left 2s cubic-bezier(0, 0.5, 0.58, 1);
  position: absolute;
  left: 70px;
}
.nextBtn:active span{
  left:300px;
  scale: 0.7;
}
.nextBtn:active i{
  scale: 0.8;
}