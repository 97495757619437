.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
}

.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: rgba(79, 79, 79, 0.5);
  height: 1px;
  margin: 0 0.5rem;
  max-width: 12rem;
}

.about-us .heading h1 {
  text-align: center;
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 4.5rem;
}

.about-us .content-title {
  font-size: 1.5rem;
  line-height: 1.4;
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 1.25rem;
  font-weight: 500;
}

.about-us .content-title h4 {
  font-weight: 500;
}

.about-us .content-text p {
  font-weight: 400;
  color: #272927;
  margin-top: 20px;
  margin-bottom: 40px;
}

.about-us .content-btn a {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #5151fd;
  margin-bottom: 20px;
}

.about-us .content .gallery-img img {
  max-width: 100%;
}

.left {
  transform: scale(-1, 1);
}

.gallery-btn1,
.gallery-btn2 {
  background: #fff;
  border: 1px solid black;
}

.gallery-btn1:hover,
.gallery-btn2:hover {
  background: #272927;
  color: #fff;
}

.content-gallery {
  background: #fefbef;
}

.splide__arrow svg {
  width: 1.5rem;
  height: 1.5rem;
}
.splide__arrow--prev svg {
  transform: none !important;
}

/* carousel mobile */
.about-us .splide__arrow--prev {
  position: unset;
  width: 3em;
  height: 3em;
  opacity: 1;
  background-color: black;
  margin-left: 1.25rem;
}

.about-us .splide__arrows {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.about-us .splide__arrow--next {
  position: unset;
  width: 3em;
  height: 3em;
  opacity: 1;
  border: 1px solid;
  background-color: white;
  margin-right: 1.25rem;
}

.aboutHr {
  color: rgba(79, 79, 79, 0.5);
}