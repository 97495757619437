
.banner {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bigText {
  /* Pursue Your Dream Education in one platform */
  font-weight: bold;
  font-size: 2.235rem;
  line-height: 1.2;
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 20px;
}
.smallText {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #272927;
  margin-bottom: 40px;
  height: auto !important;
}

.section1 .buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 87px;
}

.bannerBtn1:hover,
.bannerBtn2:hover {
  transition: all 0.3s ease-in-out;
}

.bannerBtn1,
.bannerBtn2 {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 64px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding-right: 16px;
  padding-left: 16px;
}

@media (max-width: 397px) {
  .bannerBtn1,
  .bannerBtn2 {
    font-size: 14px;
    gap: 4px;
    padding-right: 6px;
    padding-left: 6px;
    min-width: 162px;
  }
}

.bannerBtn2 {
  border: 1px solid;
}
.buttons img { 
  width: 24px;
  height: 24px;
}
.bigImg img {
  max-width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}
.groupImg {
  margin-bottom: 57px;
  position: relative;
  z-index: 4;
}

.topImg {
  display: flex;
  gap: 140px;

  justify-content: space-between;
}
.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 35%;
  top: 63%;
}
.bottomImg {
  display: flex;
  gap: 140px;
}
.trIcon img {
  width: 100px;
  height: 100px;
  position: absolute;
  margin-left: -19px;
  margin-top: -25px;
}
.polygonIcon img {
  width: 40px;
  height: 40px;
}
.polygonIcon {
  position: absolute;
  bottom: 20px;
  z-index: -1;
  left: 75px;
}
.starIcon img {
  width: 66px;
  height: 66px;
}
.starIcon {
  position: absolute;
  right: 100px;
  top: 20px;
  z-index: -1;
}
.starIcon {
  position: absolute;
}
.circleIcon img {
  width: 38px;
  height: 38px;
}
.circleIcon {
  position: absolute;
  bottom: 25px;
  right: 110px;
}

.bgg {
  background: #dcdcff;
}

.bg-texture-cta {
  background-image: url('../../../assets/Images/texture-cta-1.svg');
  background-size: cover;
}

.figures {
  min-height: 13rem;
}

.figures .content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.figures h4 {
  font-weight: 600;
  color: rgb(0, 0, 85);
  text-align: center;
}
.figures p {
  text-transform: capitalize;
  text-align: center;
  color: #272927;
}
.images .desktop {
  display: none;
}
.images .mobileB {
  display: none;
}
.images .mobileB .two {
  margin-left: -50px;
}
.images .mobileB .three {
  margin-left: -45px;
}
.images .mobileB .four {
  margin-left: -45px;
}
.images .mobileB .five {
  position: absolute;
  right: -20px;
}
