.products {
  padding: 16px;
}

.products .intro {
  text-transform: uppercase;
  color: #272927;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
}

.products .intro hr {
  flex: 1 1 auto;
  max-width: 16rem;
  color: rgba(79, 79, 79, 0.5);
}

.product button h4 {
  font-weight: 500;
}

.products .bannerBtn1 {
  min-width: 220px;
}

.heading {
  font-size: 28px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #272927;
  margin-bottom: 1rem;
}

.products .heading h4 {
  font-weight: 500;
  line-height: 1.2;
}

.products .text {
  font-size: 12px;
}

.product .list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product .list li {
  margin-bottom: 1.25rem;
}

.product .list span {
  display: flex;
  gap: 20px;
}

.product .list p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  text-transform: capitalize;
  color: #272927;
}

.product button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
  height: 60px;
  border: none;
  background: #e9e9fa;
  margin-bottom: 40px;
}
.product button:hover {
  background: #5151fd;
  color: #fff;
}
.product .product button h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}
.productImg.first {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.productImg .phone {
  display: flex;
  justify-content: center;
  align-items: center;
}
.productImg .phone .badges--container {
  width: 100%;
  height: 80%;
  /* border: 2px solid black; */
  position: absolute;
}
/* .productImg .overImg img {
  width: 12px;
  height: 12px;
} */
.productImg span p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}
.productImg {
  position: relative;
}

.productImg .laptop {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swurf {
  animation: 10s surf infinite linear;
}
.swurf2 {
  animation: 10s surf2 infinite linear;
}
@keyframes surf {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes surf2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}
